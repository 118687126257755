/* Make sure preflight.css is imported first e.g. @tailwind base; */
@import "variables.css";
@import "fonts.css";
@tailwind base;
@tailwind components;

:root {
  --nav-height: 64px;
  --narrow-nav-height: 56px;
}

.debug {
  @apply border border-red-600;
}

.debug2 {
  @apply border-2 border-blue-600;
}

.debug3 {
  @apply border border-dashed border-green-600;
}

.error {
  @apply font-bold text-oldred-600;
}

@utility text-overflow-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@layer utilities {
  .bg-diagonal-lines {
    background-image: repeating-linear-gradient(
      -45deg,
      var(--background-base-3) 0,
      var(--background-base-3) 1.5px,
      transparent 1.5px,
      transparent 6px
    );
  }
}

.error-background {
  @apply bg-oldred-100;
}

.transition-default {
  @apply transition duration-150 ease-in-out;
}

.text-link {
  @apply font-bold text-oldblue-600;
}
.text-link:hover {
  @apply text-oldblue-400;
}

/* Hide the up/down arrows on input type=numbers */
.input-no-arrows::-webkit-outer-spin-button,
.input-no-arrows::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/** Animate in - use with Radix UI popovers/tooltips etc so it knows which direction. */
@layer utilities {
  .slide-in-fade-in {
    @apply data-[state=open]:data-[side=bottom]:animate-slide-up-fade;
    @apply data-[state=open]:data-[side=left]:animate-slide-right-fade;
    @apply data-[state=open]:data-[side=right]:animate-slide-left-fade;
    @apply data-[state=open]:data-[side=top]:animate-slide-down-fade;
  }
}

@tailwind utilities;

.shadow-card {
  box-shadow: 0px 5px 8px 0px rgba(229, 229, 229, 0.1);
  box-shadow: 0px 4px 24px 0px rgba(229, 229, 229, 0.5);
}

.wordspacing-wide {
  word-spacing: 0.5em;
}

.wordspacing-normal {
  word-spacing: normal;
}

.v-screen-minus-nav {
  height: calc(100vh - var(--nav-height));
}

.v-screen-minus-narrow-nav {
  height: calc(100vh - var(--narrow-nav-height));
}

/* ANTD HACKS */
.ant-radio-button-wrapper.rounded-none:first-child .ant-radio-button-wrapper.rounded-none:last-child {
  border-radius: 0 !important;
}

/* antd sets width (on the element) to be 15px on the element, which covers the 'No Data' UI */
.ant-select-dropdown-empty {
  min-width: 100px !important;
}

button.ant-btn.ant-btn-primary {
  @apply bg-oldblue-600;
}

.ant-switch {
  @apply bg-oldgray-400;
}
.ant-switch-checked {
  @apply bg-oldblue-600 !important;
}

/* Evergreen UI hacks */
.hl-avatar > img {
  /* Force the image to go over the initials on avatar */
  position: absolute;
}

/* Style the text [selection] interface - 
note we are limited in the styling we can apply here, see https://developer.mozilla.org/en-US/docs/Web/CSS/::selection */
::selection {
  background-color: rgb(187, 211, 253);
}

/* Style windows scrollbars (as by default they're wide and boxy 
Leave Mac as-is */

/* width of the entire scrollbar */
.windows .thin-scrollbar::-webkit-scrollbar {
  width: 12px;
  height: 12px;
}

/* color of the tracking area */
.windows .thin-scrollbar::-webkit-scrollbar-track {
  background: transparent;
  /* We make the scrollbar itself round because sometimes it's inside 
  round elements, and we don't want it poking out! */
  border-radius: 20px;
}

.windows .thin-scrollbar::-webkit-scrollbar-thumb {
  /* color of the scroll thumb */
  background-color: rgb(187, 187, 187);
  /* roundness of the scroll thumb */
  border-radius: 20px;
  /* creates padding around scroll thumb */
  border: 3px solid white;
}

/* Some custom styles */

/* Adds a gradient to the edges of scrollable divs to indicates there is content not shown that can be scrolled into view.
From https://css-scroll-shadows.vercel.app/?bgColor=ffffff&shadowColor=ababab&pxSize=15
*/
.scroll-gradient {
  overflow: auto;
  background:
  /* Shadow covers */
    linear-gradient(white 30%, rgba(255, 255, 255, 0)),
    linear-gradient(rgba(255, 255, 255, 0), white 70%) 0 100%,
    /* Shadows */ radial-gradient(farthest-side at 50% 0, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0)),
    radial-gradient(farthest-side at 50% 100%, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0)) 0 100%;
  background:
  /* Shadow covers */
    linear-gradient(white 30%, rgba(255, 255, 255, 0)),
    linear-gradient(rgba(255, 255, 255, 0), white 70%) 0 100%,
    /* Shadows */ radial-gradient(farthest-side at 50% 0, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0)),
    radial-gradient(farthest-side at 50% 100%, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0)) 0 100%;
  background-repeat: no-repeat;
  background-color: white;
  background-size:
    100% 45px,
    100% 45px,
    100% 15px,
    100% 15px;
  background-attachment: local, local, scroll, scroll;
}

.scroll-gradient-x {
  /* Horizontal scrolling gradient */
  border: 1px solid grey;
  margin: 50px auto;

  overflow: auto;
  max-width: 300px;
  background:
    linear-gradient(to right, $background 20%, $transparent),
    linear-gradient(to right, $transparent, $background 80%) 0 100%,
    linear-gradient(to right, $shadow, $transparent 20%),
    linear-gradient(to left, $shadow, $transparent 20%);
  background-attachment: local, local, scroll, scroll;
}

/* Overwrite some ant design styles */
.ant-drawer-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  background: #e7ecf0;
  min-height: 3.5rem;
  max-height: 3.5rem;
  border-bottom: 1px solid #e4e7ea;
  padding-left: 16px;
}

.ant-drawer-title {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.ant-drawer-title .iconBlock {
  margin-right: 10px;
}

.ant-drawer-close {
  padding: 16px;
}

/* Ant popover relies on the tooltip and a stack of inner divs to get going - one of those (ant-popover-inner-content)
has some extra padding we don't need. */
.ant-popover-bypass {
  z-index: 1999;
}

.ant-popover-bypass .ant-popover-inner {
  background: transparent;
  box-shadow: none;
}

/* Make sure the label list always takes up the remaining space verticaly */
/* (Tailwind flex was not behaving) */

/* Ensure we show the hand, and use the same x as the rest of our UI, for search inputs 
See https://stackoverflow.com/questions/22928819/override-the-webkit-search-cancel-button */
input[type="search"]::-webkit-search-cancel-button {
  -webkit-appearance: none;
  cursor: pointer;
  height: 18px;
  width: 18px;
  background-image: url("/img/ui/close.svg");
}

/* Multiline spans with rounded borders have a blank edge when the line wraps - we add a fake background using the 
before pseudoclass and a height smaller than the actual height of the entire item to fill in that space */
.multiline-rounded-corner-background::before {
  content: "";
  position: absolute;
  z-index: 199;
  width: 1px;
  height: 75%;
  background: inherit; /* Need this to inherit since we can't generate the background color on the fly */
  left: 0px;
  top: 3px;
}

.recharts-legend-item {
  display: inline-flex !important;
  justify-content: center;
  align-items: center;
  margin-left: 1rem;
}

.recharts-surface {
  border-radius: 0.25rem;
  margin-right: 0.5rem;
}

.recharts-cartesian-axis-tick {
  font-size: 14px;
  fill: #666;
}
.recharts-cartesian-axis-tick tspan {
  /* Repeat with increased specificity to overrule tailwind's on type=number */
  font-size: 14px;
  fill: #666;
}

/** Range inputs - http://danielstern.ca/range.css/#/ */
input[type="range"] {
  width: 100%;
  margin: 2px 0;
  background-color: transparent;
  -webkit-appearance: none;
}
input[type="range"]:focus {
  outline: none;
}
input[type="range"]::-webkit-slider-runnable-track {
  background: #f3f4f6;
  border: 0;
  border-radius: 25px;
  width: 100%;
  height: 8px;
  cursor: pointer;
}
input[type="range"]::-webkit-slider-thumb {
  margin-top: -2px;
  width: 12px;
  height: 12px;
  background: #38bdf9;
  border: 0;
  border-radius: 12px;
  cursor: pointer;
  -webkit-appearance: none;
}
input[type="range"]:focus::-webkit-slider-runnable-track {
  background: #f6f7f8;
}
input[type="range"]::-moz-range-track {
  background: #f3f4f6;
  border: 0;
  border-radius: 25px;
  width: 100%;
  height: 8px;
  cursor: pointer;
}
input[type="range"]::-moz-range-thumb {
  width: 12px;
  height: 12px;
  background: #38bdf9;
  border: 0;
  border-radius: 12px;
  cursor: pointer;
}
input[type="range"]::-ms-track {
  background: transparent;
  border-color: transparent;
  border-width: 2px 0;
  color: transparent;
  width: 100%;
  height: 8px;
  cursor: pointer;
}
input[type="range"]::-ms-fill-lower {
  background: #f0f1f4;
  border: 0;
  border-radius: 50px;
}
input[type="range"]::-ms-fill-upper {
  background: #f3f4f6;
  border: 0;
  border-radius: 50px;
}
input[type="range"]::-ms-thumb {
  width: 12px;
  height: 12px;
  background: #38bdf9;
  border: 0;
  border-radius: 12px;
  cursor: pointer;
  margin-top: 0px;
  /*Needed to keep the Edge thumb centred*/
}
input[type="range"]:focus::-ms-fill-lower {
  background: #f3f4f6;
}
input[type="range"]:focus::-ms-fill-upper {
  background: #f6f7f8;
}
/*TODO: Use one of the selectors from https://stackoverflow.com/a/20541859/7077589 and figure out
how to remove the virtical space around the range input in IE*/
@supports (-ms-ime-align: auto) {
  /* Pre-Chromium Edge only styles, selector taken from hhttps://stackoverflow.com/a/32202953/7077589 */
  input[type="range"] {
    margin: 0;
    /*Edge starts the margin from the thumb, not the track as other browsers do*/
  }
}

.no-scrollbar::-webkit-scrollbar {
  display: none;
}

.scrollbar-transparent-track::-webkit-scrollbar {
  background: transparent;
  width: 10px;
}
.scrollbar-transparent-track::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.1) !important;
  border-radius: 999px;
}

/* Markdown styling */
/* Want to style the first grandchild in prose-xs to have no margin-top
/* Why grandchild? I tried removing a wrapping div but it broke the styling
*/
.prose.prose-xs > *:first-child > *:first-child {
  margin-top: 0;
}
/* Want to make the last p tag essentailly have no additional line-height 
  at the bottom, to prevent layout shift on the common case of switching the
  rendering a text block to markdown */
.prose.prose-xs > *:last-child {
  margin-bottom: -12px;
}
